define("discourse/lib/uploads", ["exports", "I18n", "discourse-common/lib/deprecated", "bootbox", "discourse/lib/utilities"], function (_exports, _I18n, _deprecated, _bootbox, _utilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.markdownNameFromFileName = markdownNameFromFileName;
  _exports.validateUploadedFiles = validateUploadedFiles;
  _exports.validateUploadedFile = validateUploadedFile;
  _exports.authorizedExtensions = authorizedExtensions;
  _exports.authorizesAllExtensions = authorizesAllExtensions;
  _exports.authorizesOneOrMoreExtensions = authorizesOneOrMoreExtensions;
  _exports.authorizesOneOrMoreImageExtensions = authorizesOneOrMoreImageExtensions;
  _exports.isImage = isImage;
  _exports.isVideo = isVideo;
  _exports.isAudio = isAudio;
  _exports.allowsImages = allowsImages;
  _exports.allowsAttachments = allowsAttachments;
  _exports.uploadIcon = uploadIcon;
  _exports.getUploadMarkdown = getUploadMarkdown;
  _exports.displayErrorForUpload = displayErrorForUpload;
  _exports.bindFileInputChangeListener = bindFileInputChangeListener;
  _exports.IMAGES_EXTENSIONS_REGEX = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function isGUID(value) {
    return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(value);
  }

  function markdownNameFromFileName(fileName) {
    var name = fileName.substr(0, fileName.lastIndexOf("."));

    if ((0, _utilities.isAppleDevice)() && isGUID(name)) {
      name = _I18n.default.t("upload_selector.default_image_alt_text");
    }

    return name.replace(/\[|\]|\|/g, "");
  }

  function validateUploadedFiles(files, opts) {
    if (!files || files.length === 0) {
      return false;
    }

    if (files.length > 1) {
      _bootbox.default.alert(_I18n.default.t("post.errors.too_many_uploads"));

      return false;
    }

    var upload = files[0];
    return validateUploadedFile(upload, opts);
  }

  function validateUploadedFile(file, opts) {
    // CHROME ONLY: if the image was pasted, sets its name to a default one
    if (typeof Blob !== "undefined" && typeof File !== "undefined") {
      if (file instanceof Blob && !(file instanceof File) && file.type === "image/png") {
        file.name = "image.png";
      }
    }

    opts = opts || {};
    opts.type = uploadTypeFromFileName(file.name);

    if (opts.skipValidation) {
      return true;
    }

    var user = opts.user;
    var staff = user && user.staff;

    if (!authorizesOneOrMoreExtensions(staff, opts.siteSettings)) {
      return false;
    }

    var name = file && file.name;

    if (!name) {
      return false;
    } // check that the uploaded file is authorized


    if (opts.allowStaffToUploadAnyFileInPm && opts.isPrivateMessage) {
      if (staff) {
        return true;
      }
    }

    if (opts.imagesOnly) {
      if (!isImage(name) && !isAuthorizedImage(name, staff, opts.siteSettings)) {
        _bootbox.default.alert(_I18n.default.t("post.errors.upload_not_authorized", {
          authorized_extensions: authorizedImagesExtensions(staff, opts.siteSettings)
        }));

        return false;
      }
    } else if (opts.csvOnly) {
      if (!/\.csv$/i.test(name)) {
        _bootbox.default.alert(_I18n.default.t("user.invited.bulk_invite.error"));

        return false;
      }
    } else {
      if (!authorizesAllExtensions(staff, opts.siteSettings) && !isAuthorizedFile(name, staff, opts.siteSettings)) {
        _bootbox.default.alert(_I18n.default.t("post.errors.upload_not_authorized", {
          authorized_extensions: authorizedExtensions(staff, opts.siteSettings).join(", ")
        }));

        return false;
      }
    }

    if (!opts.bypassNewUserRestriction) {
      // ensures that new users can upload a file
      if (user && !user.isAllowedToUploadAFile(opts.type)) {
        _bootbox.default.alert(_I18n.default.t("post.errors.".concat(opts.type, "_upload_not_allowed_for_new_user")));

        return false;
      }
    } // everything went fine


    return true;
  }

  var IMAGES_EXTENSIONS_REGEX = /(png|jpe?g|gif|svg|ico|heic|heif|webp)/i;
  _exports.IMAGES_EXTENSIONS_REGEX = IMAGES_EXTENSIONS_REGEX;

  function extensionsToArray(exts) {
    return exts.toLowerCase().replace(/[\s\.]+/g, "").split("|").filter(function (ext) {
      return ext.indexOf("*") === -1;
    });
  }

  function extensions(siteSettings) {
    return extensionsToArray(siteSettings.authorized_extensions);
  }

  function staffExtensions(siteSettings) {
    return extensionsToArray(siteSettings.authorized_extensions_for_staff);
  }

  function imagesExtensions(staff, siteSettings) {
    var exts = extensions(siteSettings).filter(function (ext) {
      return IMAGES_EXTENSIONS_REGEX.test(ext);
    });

    if (staff) {
      var staffExts = staffExtensions(siteSettings).filter(function (ext) {
        return IMAGES_EXTENSIONS_REGEX.test(ext);
      });
      exts = exts.concat(staffExts);
    }

    return exts;
  }

  function isAuthorizedFile(fileName, staff, siteSettings) {
    if (staff && new RegExp("\\.(" + staffExtensions(siteSettings).join("|") + ")$", "i").test(fileName)) {
      return true;
    }

    return new RegExp("\\.(" + extensions(siteSettings).join("|") + ")$", "i").test(fileName);
  }

  function isAuthorizedImage(fileName, staff, siteSettings) {
    return new RegExp("\\.(" + imagesExtensions(staff, siteSettings).join("|") + ")$", "i").test(fileName);
  }

  function authorizedExtensions(staff, siteSettings) {
    var exts = staff ? [].concat(_toConsumableArray(extensions(siteSettings)), _toConsumableArray(staffExtensions(siteSettings))) : extensions(siteSettings);
    return exts.filter(function (ext) {
      return ext.length > 0;
    });
  }

  function authorizedImagesExtensions(staff, siteSettings) {
    return authorizesAllExtensions(staff, siteSettings) ? "png, jpg, jpeg, gif, svg, ico, heic, heif, webp" : imagesExtensions(staff, siteSettings).join(", ");
  }

  function authorizesAllExtensions(staff, siteSettings) {
    return siteSettings.authorized_extensions.indexOf("*") >= 0 || siteSettings.authorized_extensions_for_staff.indexOf("*") >= 0 && staff;
  }

  function authorizesOneOrMoreExtensions(staff, siteSettings) {
    if (authorizesAllExtensions(staff, siteSettings)) {
      return true;
    }

    return siteSettings.authorized_extensions.split("|").filter(function (ext) {
      return ext;
    }).length > 0 || siteSettings.authorized_extensions_for_staff.split("|").filter(function (ext) {
      return ext;
    }).length > 0 && staff;
  }

  function authorizesOneOrMoreImageExtensions(staff, siteSettings) {
    if (authorizesAllExtensions(staff, siteSettings)) {
      return true;
    }

    return imagesExtensions(staff, siteSettings).length > 0;
  }

  function isImage(path) {
    return /\.(png|webp|jpe?g|gif|svg|ico)$/i.test(path);
  }

  function isVideo(path) {
    return /\.(mov|mp4|webm|m4v|3gp|ogv|avi|mpeg)$/i.test(path);
  }

  function isAudio(path) {
    return /\.(mp3|og[ga]|opus|wav|m4[abpr]|aac|flac)$/i.test(path);
  }

  function uploadTypeFromFileName(fileName) {
    return isImage(fileName) ? "image" : "attachment";
  }

  function allowsImages(staff, siteSettings) {
    return authorizesAllExtensions(staff, siteSettings) || IMAGES_EXTENSIONS_REGEX.test(authorizedExtensions(staff, siteSettings).join());
  }

  function allowsAttachments(staff, siteSettings) {
    return authorizesAllExtensions(staff, siteSettings) || authorizedExtensions(staff, siteSettings).length > imagesExtensions(staff, siteSettings).length;
  }

  function uploadIcon(staff, siteSettings) {
    return allowsAttachments(staff, siteSettings) ? "upload" : "far-image";
  }

  function imageMarkdown(upload) {
    return "![".concat(markdownNameFromFileName(upload.original_filename), "|").concat(upload.thumbnail_width, "x").concat(upload.thumbnail_height, "](").concat(upload.short_url || upload.url, ")");
  }

  function playableMediaMarkdown(upload, type) {
    return "![".concat(markdownNameFromFileName(upload.original_filename), "|").concat(type, "](").concat(upload.short_url, ")");
  }

  function attachmentMarkdown(upload) {
    return "[".concat(upload.original_filename, "|attachment](").concat(upload.short_url, ") (").concat(_I18n.default.toHumanSize(upload.filesize), ")");
  }

  function getUploadMarkdown(upload) {
    if (isImage(upload.original_filename)) {
      return imageMarkdown(upload);
    } else if (isAudio(upload.original_filename)) {
      return playableMediaMarkdown(upload, "audio");
    } else if (isVideo(upload.original_filename)) {
      return playableMediaMarkdown(upload, "video");
    } else {
      return attachmentMarkdown(upload);
    }
  }

  function displayErrorForUpload(data, siteSettings, fileName) {
    if (!fileName) {
      (0, _deprecated.default)("Calling displayErrorForUpload without a fileName is deprecated and will be removed in a future version.");
      fileName = data.files[0].name;
    }

    if (data.jqXHR) {
      var didError = displayErrorByResponseStatus(data.jqXHR.status, data.jqXHR.responseJSON, fileName, siteSettings);

      if (didError) {
        return;
      }
    } else if (data.body && data.status) {
      var _didError = displayErrorByResponseStatus(data.status, data.body, fileName, siteSettings);

      if (_didError) {
        return;
      }
    } else if (data.errors && data.errors.length > 0) {
      _bootbox.default.alert(data.errors.join("\n"));

      return;
    } // otherwise, display a generic error message


    _bootbox.default.alert(_I18n.default.t("post.errors.upload"));
  }

  function displayErrorByResponseStatus(status, body, fileName, siteSettings) {
    switch (status) {
      // didn't get headers from server, or browser refuses to tell us
      case 0:
        _bootbox.default.alert(_I18n.default.t("post.errors.upload"));

        return true;
      // entity too large, usually returned from the web server

      case 413:
        var type = uploadTypeFromFileName(fileName);
        var max_size_kb = siteSettings["max_".concat(type, "_size_kb")];

        _bootbox.default.alert(_I18n.default.t("post.errors.file_too_large_humanized", {
          max_size: _I18n.default.toHumanSize(max_size_kb * 1024)
        }));

        return true;
      // the error message is provided by the server

      case 422:
        if (body.message) {
          _bootbox.default.alert(body.message);
        } else {
          _bootbox.default.alert(body.errors.join("\n"));
        }

        return true;
    }

    return;
  }

  function bindFileInputChangeListener(element, fileCallbackFn) {
    function changeListener(event) {
      var files = Array.from(event.target.files);
      files.forEach(function (file) {
        fileCallbackFn(file);
      });
    }

    element.addEventListener("change", changeListener);
    return changeListener;
  }
});